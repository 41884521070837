import React from "react";
import { Parallax } from "react-scroll-parallax";
import tilt_border from "../../../assets/tilt_border.png";

interface AboutImageContainerProps {
  className?: string;
  backgroundAttachmentString?: string;
}

class AboutImageContainerWithHeaderOnly extends React.Component<
  AboutImageContainerProps,
  {}
> {
  public render() {
    return (
      <div>
        <Parallax
          styleInner={{
            backgroundAttachment: this.props.backgroundAttachmentString,
            height: `inherit`,
            width: `100%`
          }}
          className={this.props.className}
          styleOuter={{
            backgroundAttachment: this.props.backgroundAttachmentString
          }}
        />
        <div
          style={{
            marginTop: `-7%`,
            position: `absolute`,
            width: `100%`
          }}
        >
          <img
            className="container-header"
            alt="Bottom Border"
            style={{ animation: `neutral 5s forwards` }}
            src={tilt_border}
          />
        </div>
      </div>
    );
  }
}

export default AboutImageContainerWithHeaderOnly;
