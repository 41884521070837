import { graphql, navigate, useStaticQuery } from "gatsby";
import parse from "html-react-parser";
import React, { AnimationEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SuryaSoftLogo from "../../../assets/surya-master-02.png";
import "../layout.css";

const colors: string[] = ["#222222", "#444444", "#333333"];

export default () => {
  let count = 0;
  const { i18n, t } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        first: markdownRemark(
          frontmatter: { index: { eq: 1 }, langKey: { eq: "en-US" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
          html
          htmlAst
        }
        second: markdownRemark(
          frontmatter: { index: { eq: 2 }, langKey: { eq: "en-US" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
        }
        third: markdownRemark(
          frontmatter: { index: { eq: 3 }, langKey: { eq: "en-US" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
        }
        firstFr: markdownRemark(
          frontmatter: { index: { eq: 1 }, langKey: { eq: "fr" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
          htmlAst
          html
        }
        secondFr: markdownRemark(
          frontmatter: { index: { eq: 2 }, langKey: { eq: "fr" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
        }
        thirdFr: markdownRemark(
          frontmatter: { index: { eq: 3 }, langKey: { eq: "fr" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
        }
        suryaSoftLogo: file(relativePath: { eq: "surya-soft-logo.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  function incrementCount(event?: AnimationEvent<HTMLDivElement>): void {
    count++;
  }

  const lang = i18n.language;
  const dataFirst = i18n.language === "en-US" ? data.first : data.firstFr;
  const dataSecond = lang === "en-US" ? data.second : data.secondFr;
  const dataThird = lang === "en-US" ? data.third : data.thirdFr;

  return (
    <div className="society-container">
      <div className="card-div1" style={{ marginTop: `-236px` }}>
        <div
          className="card"
          style={{
            backgroundColor: colors[count]
          }}
        >
          {incrementCount()}
          <div>{parse(dataFirst.excerpt)}</div>
          <div className="button-div">
            <a
              className="button"
              href={dataFirst.frontmatter.buttonUrl}
              style={{
                color: `white`,
                outline: `none`,
                textDecoration: `inherit`
              }}
              onClick={() => {
                if (typeof localStorage !== "undefined") {
                  localStorage.setItem("scrollPosition", (0).toString());
                }
              }}
            >
              {dataFirst.frontmatter.buttonTitle}
            </a>
          </div>
        </div>
        <div className="card-right" style={{ backgroundColor: colors[count] }}>
          {incrementCount()}
          <div>{parse(dataSecond.excerpt)}</div>
          <a
            className="button"
            onClick={() => {
              if (typeof localStorage !== "undefined") {
                localStorage.setItem("scrollPosition", (0).toString());
              }
              navigate(dataSecond.frontmatter.buttonUrl);
            }}
            style={{
              display: `none`
            }}
          >
            {dataSecond.frontmatter.buttonTitle + " » "}
          </a>
        </div>
      </div>
      <div className="card-div2">
        <div className="card-lower" style={{ backgroundColor: colors[count] }}>
          {incrementCount()}
          <div>{parse(dataThird.excerpt)}</div>
          <button
            className="button"
            onClick={() => {
              navigate(dataThird.frontmatter.buttonUrl);
            }}
            style={{
              display: `none`
            }}
          >
            {dataThird.frontmatter.buttonTitle + " » "}
          </button>
        </div>
        <div className="society-logos-div">
          <a
            href="https://www.surya-soft.com/"
            className="society-logos-link"
            style={{ color: `inherit` }}
            target="_blank"
          >
            <img className="society-div2-logo" src={SuryaSoftLogo} />
            www.surya-soft.com
          </a>
        </div>
      </div>
    </div>
  );
};
