import React from "react";
import { isIOS, isIOS13, isIPad13, isMobile } from "react-device-detect";
import { withTranslation, WithTranslation } from "react-i18next";
import Container from "../components/container";
import Layout from "../components/layout";
import "../components/layout.css";
import SocietyContainer from "../components/society/aboutContainer";
import SocietyImageContainerWithHeaderOnly from "../components/society/aboutImageContainerWithHeaderOnly";

interface AboutImageContainerStates {
  backgroundAttachmentString?: string;
}

// tslint:disable-next-line: no-empty-interface
interface AboutImageContainerProps extends WithTranslation {}

class About extends React.Component<
  AboutImageContainerProps,
  AboutImageContainerStates
> {
  constructor(props: AboutImageContainerProps) {
    super(props);
    this.state = {
      backgroundAttachmentString: `fixed`
    };
  }

  public componentDidMount() {
    this.setState({
      backgroundAttachmentString:
        isMobile || isIOS || isIPad13 || isIOS13 ? "scroll" : "fixed"
    });
  }

  public render() {
    return (
      <Layout>
        <SocietyImageContainerWithHeaderOnly
          className="background-society"
          backgroundAttachmentString={this.state.backgroundAttachmentString}
        />
        <div>
          <Container style={{ overflow: `visible`, display: `flex` }}>
            <SocietyContainer />
          </Container>
        </div>
      </Layout>
    );
  }
}

export default withTranslation()(About);
